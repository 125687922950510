import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../layouts/default';
import Breadcrumb from '../component-elements/breadcrumb';
import CmsMulticolumnBrand from '../brands-component/content-elements/CmsMulticolumnBrand';
import CategoryHeader from '../components/shop-category/header';
import CategoryHelmet from '../components/shop-category/helmet';
import ProductGrid from '../components/shop-category/product-grid';

import * as styles from './styles/breadcrumb.module.scss';

const CategoryTemplate = ({ data, pageContext, location }) => {
  console.log('Start parsing category template.');
  const { magentoCategory, allMagentoProduct } = data;

  const {
    lang,
    pagerPageCount,
    activePageIndex,
    productCount,
    url,
  } = pageContext;

  const parentBreadcrumbItems = (magentoCategory.breadcrumbs || []).map((
    { category_name: name },
    i,
    all,
  ) => ({
    id: i.toString(),
    name,
    url: (() => {
      const path = all.reduce((c, { category_url_key: seg }, j) => {
        if (j <= i) {
          return [...c, seg];
        }
        return c;
      }, []);
      return `/${path.join('/')}/`;
    })(),
  }));

  const components = {
    DatoCmsMulticolumnText: CmsMulticolumnBrand,
  };

  return (
    <Layout textcolorHeader="white" textcolorHeaderMobile="white">
      {(magentoCategory.breadcrumbs || magentoCategory.name) && (
        <Breadcrumb
          items={[
            ...parentBreadcrumbItems,
            { id: magentoCategory.magento_id.toString(), name: magentoCategory.name },
          ]}
          className={`${styles.breadcrumb} ${styles.headerTextcolorwhite} ${styles.headerMobileTextcolorwhite}`}
        />
      )}

      <CategoryHeader
        headline={magentoCategory.name}
        teaser={(magentoCategory.cmsMeta?.categoryBanner || []).filter((b) => b.category)}
      />

      <ProductGrid
        staticFilters={[{
          key: 'category_id',
          values: [magentoCategory.magento_id],
        }]}
        initialAggregations={magentoCategory.aggregations ?? []}
        initialPageCount={pagerPageCount}
        initialPage={activePageIndex + 1}
        items={allMagentoProduct.nodes}
        lang={lang}
        location={location}
        initialItemTotal={productCount}
        sortFields={magentoCategory.sortFields}
        categories={magentoCategory.childCategories}
        breadcrumb={[
          ...parentBreadcrumbItems,
          {
            id: magentoCategory.magento_id.toString(),
            name: magentoCategory.name,
            url: magentoCategory.fullUrlPath,
          },
        ]}
      />

      {magentoCategory.cmsMeta?.content.map((singleComponent) => {
        if ((typeof singleComponent === 'object') && Object.keys(singleComponent).length > 0) {
          const Component = components[singleComponent.__typename];
          return (
            <Component
              key={singleComponent.id}
              {...singleComponent}
            />
          );
        }
        return (
          <div><h4>This content element can not be rendered.</h4></div>
        );
      })}

      <CategoryHelmet
        name={magentoCategory.name}
        url={url}
        seoTags={magentoCategory.cmsMeta?.seoMetaTags}
        seoTitle={magentoCategory.cmsMeta?.seo?.title}
        seoDescription={magentoCategory.cmsMeta?.seo?.description}
        seoImage={magentoCategory.cmsMeta?.teaserImage}
        robots={magentoCategory.cmsMeta?.metaRobots}
        canonical={magentoCategory.cmsMeta?.metaCanonical}
        isPaged={activePageIndex > 0}
        hasContent={magentoCategory.cmsMeta?.content.length > 0}
      />
    </Layout>
  );
};
export default CategoryTemplate;

CategoryTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
query categoryQuery($id: String!, $lang: String!, $magentoId: Int!, $productsPerPage: Int!, $skipProducts: Int!) {
  magentoCategory(locale: {eq: $lang}, id: {eq: $id}) {
    magento_id

    aggregations {
        label
        attribute_code
        options {
            label
            value
        }
    }

    sortFields {
        label
        value
    }

    name
    fullUrlPath

    breadcrumbs {
      category_name
      category_url_key
    }

    childCategories {
      name
      fullUrlPath
    }

    cmsMeta {
      seoMetaTags {
        tags
      }

      seo {
        title
        description
      }
      
      teaserImage {
          url
          alt
      }
      
      categoryBanner {
        id
        category: magentoCategory {
          name
          fullUrlPath
        }
        teaserName
        teaserImage {
          alt
          gatsbyImageData(
            forceBlurhash: true
            imgixParams: {
              w: "350"
              h: "350"
              auto: "compress"
              fit: "crop"
              crop: "faces"
              dpr: 1.5
              q: 70
            }
          )
        }
      }
      
      metaCanonical
      metaRobots
      content {
        ...ce_multicolumn_text
      }
    }
  }

  allMagentoProduct(
    filter: {
      categories: { elemMatch: { id: { eq: $magentoId } } }, 
      locale: { eq: $lang }
    }
    limit: $productsPerPage
    skip: $skipProducts
    sort: { fields: name, order: ASC }
  ) {
    nodes {
      ...shopProductListItem
    }
  }
}
`;
