import React from 'react';
import PropTypes from 'prop-types';

import { imageFallbackPropType } from '../../helmet/propTypes';

import t from '../../../lib/translate';

import Helmet from '../../helmet';

const CategoryHelmet = ({
  name: categoryName,
  seoTags: categoryTags = { tags: [] },
  seoTitle,
  seoDescription,
  seoImage,
  url: categoryUrl,
  robots: categoryRobots,
  canonical: categoryCanonical,
  isPaged = false,
  hasContent = false,
}) => {
  const metaRobots = (isPaged || !hasContent) ? 'noindex-follow' : categoryRobots;
  const metaCanonical = (isPaged || !hasContent) ? null : categoryCanonical;

  // Replace existing SEO metas if they have no content
  const seoMetaTags = categoryTags.tags
    /* remove og:type */
    .filter(({ attributes: { property = undefined } = {} }) => property !== 'og:type');

  // Add additional OG metas
  seoMetaTags.push(
    {
      tagName: 'meta',
      attributes: {
        property: 'og:url',
        content: `${process.env.GATSBY_WEB_URL}${categoryUrl}`,
      },
    },
  );

  return (
    <Helmet
      tags={{ tags: seoMetaTags }}
      ogType="product.group"
      ogSiteName={t('shop_category_seo_sitename', categoryName)}
      metaRobots={metaRobots}
      metaCanonical={metaCanonical}
      title={seoTitle}
      titleFallback={t('shop_category_seo_title_fallback', categoryName)}
      description={seoDescription}
      descriptionFallback={t('shop_category_seo_description_fallback', categoryName)}
      imageFallback={seoImage}
      url={categoryUrl}
    />
  );
};

CategoryHelmet.defaultProps = {
  seoTags: {
    tags: [],
  },
  isPaged: false,
  hasContent: false,
  robots: undefined,
  canonical: undefined,
  seoTitle: undefined,
  seoDescription: undefined,
  seoImage: undefined,
};

CategoryHelmet.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  seoTags: PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.shape({
      tagName: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      content: PropTypes.any,
      attributes: PropTypes.shape({
        property: PropTypes.string,
        name: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        content: PropTypes.any,
      }),
    })),
  }),
  seoImage: imageFallbackPropType,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  robots: PropTypes.string,
  canonical: PropTypes.string,
  isPaged: PropTypes.bool,
  hasContent: PropTypes.bool,
};

export default CategoryHelmet;
